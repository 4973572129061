<template>
	<div class="mt-50 cartel-main">
		<template
			v-if="mode != 'total'"
		>
			<div class="box-recomm">
				<div
					class="box-title"
				>
					<h3 class="mr-15">추천 카르텔</h3>
					<h6>메타마피아 대표 카르텔을 소개합니다.</h6>
					<div
						class="box-nav"
					>
						<span
							class="arrow-left mr-40"  :class="{on: is_on.recomm_left}"
							@click="toLeft('recomm')"
						></span>
						<span
							class="arrow-right"  :class="{on: is_on.recomm_right}"
							@click="toRight('recomm')"
						></span>
					</div>
				</div>
				<div
					class="box-list mt-25"
					style="overflow: hidden; white-space: nowrap;"
				>
					<ul
						ref="box-recomm"
						class="scroll-black"
						style="overflow: auto"
					>
						<li
							v-for="(item, index) in items_cartel_recomm"
							:key="'item_recomm_' + index"
							class=""
							@click="toDetail(item)"
						>
							<div class="box-img">
								<div
									style="height: 100%;"
								>
									<img
										:src="$request.upload_url(item.cartl_img_url)"
										@error="$event.target.src=require('@/assets/image/@noimage.png')"
									/>
								</div>
								<div class="gradient-title">
									<span class="title">{{ item.cartl_name}}</span>
								</div>
							</div>
							<div class="discription mt-20">{{  item.cartl_name }}</div>
						</li>
					</ul>
				</div>
			</div>
			<!-- 추천 카르텔 -->

			<div class="box-recomm">
				<div
					class="box-title"
				>
					<h3 class="mr-15">가입 카르텔</h3>
					<h6>내가 가입된 카르텔입니다.</h6>
					<div
						class="box-nav"
					>
						<span
							@click="toLeft('join')"
							class="arrow-left mr-40" :class="{on: is_on.join_left}"
						></span>
						<span
							class="arrow-right" :class="{on: is_on.join_right}"
							@click="toRight('join')"
						></span>
					</div>
				</div>
				<div
					class="box-list mt-20"
					style="overflow: hidden; white-space: nowrap;"
				>
					<ul
						ref="box-join"
						class="scroll-black"
						style="overflow: auto"
					>
						<li
							v-for="(item, index) in items_my_cartel"
							:key="'item_join_' + index"
							class=""
							@click="toDetail(item)"
						>
							<div class="box-img">
								<img
									:src="$request.upload_url(item.cartl_img_url)"
									@error="$event.target.src=require('@/assets/image/@noimage.png')"
								/>
								<div class="gradient-title">
									<span class="title">{{ item.cartl_name}}</span>
								</div>
							</div>
							<div class="name mt-20">{{  item.cartl_name }}</div>
						</li>
					</ul>
				</div>
			</div>
		</template>

		<div class="box-all">
			<div
				class="box-title"
			>
				<h3 class="mr-15">전체 카르텔</h3>
				<h6>메타마피아에 등록된 카르텔을 확인하세요.</h6>
				<div
					class="box-nav"
				>
					<button
						v-if="mode != 'total'"
						@click="toCartelList('total')"
					>더보기</button>
				</div>
			</div>
			<div
				class=" mt-25"
			>
				<ul class="text-left ">
					<li
						v-for="(item, index) in items_cartel"
						:key="'item_' + index"
						class="inline-block cursor-pointer"
						@click="toDetail(item)"
					>
						<div
							class="flex-row"
						>
							<div class="box-img ">
								<img
									:src="$request.upload_url(item.cartl_img_url)"
									@error="$event.target.src=require('@/assets/image/@noimage.png')"
								/>
							</div>

							<div
								class="box-info flex-1"
							>
								<div class="title ">{{  item.cartl_name }}</div>
								<div class="count mt-10 flex-row"><span class="flex-1">전체 글</span> <span class="flex-2">{{  item.count_a | makeComma }}</span></div>
								<div class="count mt-10 flex-row"><span class="flex-1">새글</span> <span class="flex-2">{{  item.count_n | makeComma }}</span></div>
							</div>
						</div>
					</li>
				</ul>


				<template
					v-if="mode == 'total'"
				>
					<ul
					>
						<li
							v-for="(item, index) in items_cartel"
							:key="'item_' + tindex + '_' + index"
							class="inline-block cursor-pointer"
							@click="toDetail(item)"
						>
							<div
								class="flex-row"
							>
								<div class="box-img ">
									<img
										:src="$request.upload_url(item.cartl_img_url)"
										@error="$event.target.src=require('@/assets/image/@noimage.png')"
									/>
								</div>

								<div
									class="box-info flex-1"
								>
									<div class="title ">{{  item.cartl_name }}</div>
									<div class="count mt-10 flex-row"><span class="flex-1">전체 글</span> <span class="flex-2">{{  item.count_a | makeComma }}</span></div>
									<div class="count mt-10 flex-row"><span class="flex-1">새글</span> <span class="flex-2">{{  item.count_n | makeComma }}</span></div>
								</div>
							</div>
						</li>
					</ul>
				</template>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		name: 'mafia044'
		, props: ['user']
		, data: function(){
			return {
				program: {
					name: 'cartel'
					, title: '카르텔 목록'
					, type: 'cartel'
				}
				, items_cartel: []
				, items_cartel_recomm: []
				, items_my_cartel: []
				, cartl_open_possible_fg: ''
				, item_search: {
					page_number: this.$language.base.page_number
					, pagerecnum: this.$language.base.pagerecnum
				}
				, item_slide: {
					join: {
						d: 1200
						, p: 1
						, tp: 1
						, doing: false
					}
					, recomm: {
						d: 1200
						, p: 1
						, tp: 1
						, doing: false
					}
				}
				, mode: ''
			}
		}
		, computed: {
			is_create: function(){
				let t = false

				if(this.cartl_open_possible_fg == 'Y'){
					t = true
				}

				return t
			}

			, is_on: function(){
				let t = {
					recomm_left: false
					, recomm_right: true
					, join_left: false
					, join_right: true
				}

				if(this.item_slide.join.p > 1){
					t.join_left = true
				}else{
					t.join_left = false
				}
				if(this.item_slide.join.tp == 1 || this.item_slide.join.p == this.item_slide.join.tp){
					t.join_right = false
				}else{
					t.join_right = true
				}

				if(this.item_slide.recomm.p > 1){
					t.recomm_left = true
				}else{
					t.recomm_left = false
				}
				if(this.item_slide.recomm.tp == 1 || this.item_slide.recomm.p == this.item_slide.recomm.tp){
					t.recomm_right = false
				}else{
					t.recomm_right = true
				}

				return t
			}
		}
		, methods: {
			getData: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_cartel_main_info
						, data: {
							member_number: this.user.member_number
							, cartl_list_page: {
								page_number: this.item_search.page_number
								, pagerecnum: this.item_search.pagerecnum
							}
							, nrt_post_list_page: {
								page_number: this.item_search.page_number
								, pagerecnum: this.item_search.pagerecnum
							}
						}
						, type: true
					})

					if(result.success){
						this.items_cartel_recomm = result.data.recom_cartl_list
						this.items_my_cartel = result.data.member_cartl_list
						this.items_cartel = result.data.entry_cartl_nrt_post_list
						this.cartl_open_possible_fg = result.data.cartl_open_possible_fg

						setTimeout( () => {
							let type = 'recomm'
							let t = this.$refs["box-" + type].scrollWidth

							this.item_slide[type].tp = Math.ceil(t / this.item_slide[type].d)

							type = 'join'
							t = this.$refs["box-" + type].scrollWidth

							this.item_slide[type].tp = Math.ceil(t / this.item_slide[type].d)
						}, 100)
					}else{
						throw result.message
					}
				}catch (e) {
					this.$log.console(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, toDetail: function(item){
				this.$bus.$emit('to', { name: 'mafia049', params: {idx: item.cartl_number}})
			}
			, toRight: function(type) {

				if (!this.item_slide[type].doing) {

					this.item_slide[type].doing = true

					if (this.item_slide[type].p >= this.item_slide[type].tp) {
						this.item_slide[type].p = 0
					}

					let x = this.item_slide[type].d * this.item_slide[type].p

					this.$refs["box-" + type].scrollTo({
						left: x + (25 * this.item_slide[type].p), behavior: 'smooth'
					})

					this.item_slide[type].p++
					this.item_slide[type].doing = false

				}
			}

			, toLeft: function(type){

				if(!this.item_slide[type].doing){

					this.item_slide[type].doing = true

					let t = this.$refs["box-" + type].scrollWidth

					this.item_slide[type].tp = Math.ceil(t / this.item_slide[type].d)

					this.item_slide[type].p--

					if(this.item_slide[type].p <= 0){
						this.item_slide[type].p = this.item_slide[type].tp
					}
					let x = this.item_slide[type].d * this.item_slide[type].p - this.item_slide[type].d + (25 * this.item_slide[type].p - 25)

					this.$refs["box-" + type].scrollTo({
						left: x, behavior: 'smooth'
					})

					this.item_slide[type].doing = false
				}
			}
			, toCartelList: function(type){

				this.mode = type

				if(type == 'total'){
					this.$bus.$emit('to', { path: this.$route.path + '#total'})
				}else{
					this.$bus.$emit('to', { name: 'main'})
				}
			}
			, getCartelMain: function(){
				if((this.$route.fullPath).indexOf('#total') > -1){
					this.mode = 'total'
				}else if((this.$route.fullPath).indexOf('#search') > -1) {
					this.mode = 'search'
				}else{
					this.mode = ''
				}
			}
		}
		, async created() {
			this.$log.console('044 user', this.user)
			this.$emit('onLoad', this.program)
			this.getCartelMain()
			await this.getData()
		}
		, watch:{
			$route(to){
				this.mode = ''
				if(to.hash){
					this.getCartelMain()
				}
			}
		}
	}
</script>

<style>
	.cartel-main .box-title {
		text-align: left;
		display: flex;
		/* padding: 10px; */
		align-items: baseline;

	}

	.cartel-main .box-title h3 {
		flex-basis: 120px;
	}
	.cartel-main .box-title h6 {
		flex: 1;
		line-height: 260%;
		font-weight: 500;
	}

	.cartel-main .box-title .box-nav {
		text-align: right;
		flex-basis: 80px;
	}

	.cartel-main .box-list ul {
		text-align: left;
	}
	.cartel-main .box-list li{
		display: inline-block;
		margin-right: 25px;
		width: 220px; height: 370px;
		text-align: center;
		cursor: pointer;
	}
	.cartel-main .box-list li:nth-child(5n){

	}
	.cartel-main .box-list li .box-img{
		width: 218px; height: 218px;
		border-radius: 30%;
		border: none;
		overflow: hidden;
		position: relative;
		background-color: #eee;
		-webkit-mask-image: url(../../assets/image/web/box_mask_218.png);
		mask-image: url(../../assets/image/web/box_mask_218.png);
	}

	.cartel-main .box-list li .box-img img {
		width: 100%; height: 100%; object-fit: cover;
	}

	.cartel-main .box-list li .box-img .gradient-title{

		color: white;
		position: absolute;
		bottom: 0px; left: 0px;
		padding: 20px 20px;
		width: 100%;
		height: 100%;
		background: linear-gradient(180deg, rgba(0, 0, 0, 0) 46.35%, rgba(0, 0, 0, 0.8) 100%);
		display: flex; flex-direction: column; justify-content: flex-end;
	}
	.cartel-main .box-list li .box-img .title {

		width: 100%;
		max-height: 45%;
		padding: 20px 0px;
		font-size: 20px;
		overflow: hidden;
		white-space: normal;
		font-weight: 700;
	}
	.cartel-main .box-list li .discription {
		font-size: 16px; color: #333; font-weight: 400; font-family: Noto Sans KR;
		/* padding: 10px; */
	}
</style>